window.addEventListener(
  'DOMContentLoaded',
  () => {
    const favoriteBtns = $('.c-button.-border-orange.-heart-icon');
    // issue_4141の施策の結果次第で修正
    // 横幅が狭いボタンの場合テキストが入らないため文字をカスタマイズ
    const isShortText = favoriteBtns
      .map(function () {
        return $(this).attr('data-is-square') === 'true';
      })
      .get()
      .some(Boolean);

    const favOn = {
      class: 'c-button -border-orange -heart-icon -sp-text-sm',
      'data-method': 'post',
      innerHTML: isShortText ? 'お気に入り' : 'お気に入り登録',
      href: '/mypage/favorites?job_id=',
    };
    const favOff = {
      class: 'c-button -border-orange -heart-icon -sp-text-sm -solid -done',
      'data-method': 'delete',
      innerHTML: isShortText ? 'お気に入り' : 'お気に入り登録済',
      href: '/mypage/favorites/',
    };
    function toggle(favBtn) {
      if (favBtn.className.includes(favOff.class)) {
        // お気に入り登録済みのクラスを削除
        favBtn.classList.remove('-solid', '-done');
        favBtn.innerHTML = favOn.innerHTML;
        favBtn.href = favOn.href + favBtn.id;
        favBtn.setAttribute('data-method', favOn['data-method']);
      } else if (favBtn.className.includes(favOn.class)) {
        // お気に入り登録済みのクラスを追加
        favBtn.classList.add('-solid', '-done');
        favBtn.innerHTML = favOff.innerHTML;
        favBtn.href = favOff.href + favBtn.id;
        favBtn.setAttribute('data-method', favOff['data-method']);
      }
    }

    $.each(favoriteBtns, function (i, theBtn) {
      $(this).on('ajax:success', () => {
        $.each(favoriteBtns, (j, btn) => {
          if (btn.id === theBtn.id) {
            toggle(btn);
          }
        });
      });
    });
  },
  false
);
